import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useForm, useFormState, useController, useWatch } from "react-hook-form";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ProjectAutocompleteObjectInput } from '../Project/ProjectAutocomplete';
import { PostSaleAutocompleteObjectInput } from '../PostVenta/PostSaleAutocomplete';
import { POrderAutocompleteObjectInput } from '../Compras/POrder/POrderAutocomplete';
import { PageBase, Form, TextInput } from '../../components';
import { postAPI, getAPI, checkUserRoles, useTabs, activityLog } from '../../utils';

import {options_orders_fabrica_status, options_orders_servicios_status, options_sino} from '../../appConfig';

import moment from 'moment';

const WorkOrderEdit = ({
        doc={}, 
        edit=false,
        tipo='WORK-F',
        debug=false,
        onAfterSubmit=() => {},
        onCancel=() => {},
        ...other
    }) => {

    let params = useParams();
    const [obraId, setObraId] = useState(doc && doc['obra'] && doc['obra']['_id']);
    const [archivos_planilla, setPlanillas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [disableFechaAprobacion, setDisableFechaAprobacion] = useState(false);
    const [disableFechaEntrega, setDisableFechaEntrega] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {appendTab} = useTabs();

    let docId = doc['_id'] || params.id;
    const opciones_archivo_planilla = archivos_planilla.map( it => {return {label: it.filename, value: it._id}})

    const resources = useSelector(state => state.resources);
    const options_recursos0 = tipo === 'WORK-F' ? resources['fabrica'] : resources['servicio'];
    const options_recursos1 = options_recursos0.map( it => {return {label: it.nombre, value: it.nombre}})

    const transports = useSelector(state => state.transports);
    const options_transports = transports.map(it => ({label: it.nombre, value: it._id, ...it}))

    const options_status = tipo === 'WORK-F' ? options_orders_fabrica_status : options_orders_servicios_status;
    const options_status1 = options_status.map(it => {return {label:it, value:it}});

    const currentUser = useSelector(state => state.currentUser);
    const hidden = !checkUserRoles(currentUser, ['local-admin'])

    const optionsAll = useSelector(state => state.options) || {};
    const options_categoria_of = optionsAll['tipoOF'] || [];
    const options_categoria_os = optionsAll['tipoOS'] || [];

    useEffect( () => {
        debugger
        if (doc && doc['obra'] && doc['obra']['_id']) {
            setObraId(doc['obra']['_id']);

            if (!!doc['fecha_entrega']) {
                setDisableFechaEntrega(true)
            }
            if (!!doc['fecha_aprobacion']) {
                setDisableFechaAprobacion(true)
            }
        }
    }, [doc])

    useEffect( () => {
        debugger
        if ( !obraId ) return;
        getAPI('/project/' + obraId)
        .then(data => {
            debugger
            // if (data) setPlanillas(data['archivo_medicion'] || [])
            if (data && data['measurements']) setPlanillas(data['measurements'].map(it => it.archivo) || [])
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
          });   
    }, [obraId, enqueueSnackbar])
  
    const onSubmit = (data, diffMgs) => {
        debugger
        // validacion
        if (data['tipo']==='fabrica' && data['obra'] && data['archivo_planilla'] && !data['archivo_planilla']['_id']) {
            enqueueSnackbar("Se debe cargar una planilla de obra para este tipo de trabajo", {variant: 'error'})
            return
        }
        if (!data['obra'] && !data['pv']) {
            enqueueSnackbar("Se debe relacionar o una Obra o un PV", {variant: 'error'})
            return
        }
        if (data['np'] === "") delete data['np']; //OBS no se porque viene con ""
        if (data['obra'] === "") delete data['obra'];
        if (data['pv'] === "") delete data['pv'];
        
        if (data['transporte']) {
            data['transporte'] = {_id: data['transporte']}
        }
        
        if (docId === 'new') docId = "";
        postAPI('/work/workorders/' + docId, data)
        .then( ({res, doc}) => {
            const app = tipo === "WORK-F" ? 'fabrica' : 'servicio';
            const tpo = tipo === "WORK-F" ? 'OF' : 'OS';
            debugger
            if (docId === '') {
                const msg = "Se creó nueva orden de " + app;
                activityLog({label: tpo + "-" + doc.of, app, docId:doc._id, msg, currentUser})
                enqueueSnackbar(msg, {variant: 'info'})
            } else {
                activityLog({label: tpo + "-" + doc.of, app, docId, diffMgs, currentUser})
                const msg = "Se modificó la orden de " + app;
                enqueueSnackbar(msg, {variant: 'info'})
            }
            onAfterSubmit(doc)
        })
        .catch(err => {
            debugger
            setError({title: err.message})
        });
    }
  
    const handleGotoPO = (ev) => {
        appendTab("NP")(doc.np)
    }
  
    const watchChanges = (value, name, type, getValues, setValue, setError, clearErrors) => {
        if ( type==='change') {
            debugger
            if (name === 'obra') {
                // recarga planillas
                const oo = getValues('obra')
                setObraId(oo && oo['_id'])                

                if (getValues('pv')) {
                    setValue('pv', undefined);
                }

                if (!oo) {
                    setValue('archivo_planilla._id', undefined)
                    setPlanillas([])
                }
            }
            if (name === 'pv') {
                if (getValues('archivo_planilla._id')) {
                    //setValue('obra', undefined);
                    setValue('archivo_planilla._id', undefined)
                    setPlanillas([])
                }
                if (getValues('pv.obra')) {
                    setValue('obra', getValues('pv.obra'))
                    setObraId(getValues('pv.obra')._id)                    
                } else {
                    setValue('obra', '')
                    setPlanillas([])
                }
            }
            // if (name === 'pv' || name === 'obra') {
            //     if (!getValues('pv') && !getValues('obra')) {
            //         setError(name, {type: 'custom', message: 'Debe seleccionar un PV o OBRA'})
            //     }
            // }

            if (
                name === 'plan_fecha_inicio'       || name === 'fecha_inicio' || 
                name === 'plan_fecha_finalizacion' || name === 'fecha_finalizacion'
            ) {
                let key_start = 'fecha_inicio';
                let key_end   = 'fecha_finalizacion';
                let key_dur   = 'duracion';
                if (name.indexOf('plan') > -1) {
                    key_start = 'plan_' + key_start;
                    key_end   = 'plan_' + key_end;
                    key_dur   = 'plan_' + key_dur;
                }
                const f1 = getValues(key_start);
                const f2 = getValues(key_end);
                debugger
                if (f1 && f2 && f1.startOf) {
                    const dife = f2.startOf('day').diff(f1.startOf('day'), 'days')
                    if (dife < 0) {
                        setError(name, {type: 'custom', message: 'Fecha inicio debe ser anterior a finalizacion'})
                    } else {
                        setValue(key_dur, dife)
                        clearErrors([key_start, key_end])
                    }
                }
            }
            if ( name === 'duracion' || name === 'plan_duracion') {
                let key_start = 'fecha_inicio';
                let key_end   = 'fecha_finalizacion';
                if (name.indexOf('plan') > -1) {
                    key_start = 'plan_' + key_start;
                    key_end   = 'plan_' + key_end;
                }
                let f1 = getValues(key_start);
                const dur = getValues(name);
                if (f1 && dur != null) {
                    f1 = moment(f1);
                    if (dur >= 0) {
                        debugger
                        const f2 = f1.clone()
                        f2.add(dur, 'days').startOf('day');
                        setValue(key_end, f2)
                    } else {
                        setError(name, {type: 'custom', message: 'La duración no puede ser negativa'})
                    }
                }
            }
        }
    }

    const diffFnPlanilla = (orig) => {
        if (!orig || Object.keys(orig).length === 0) return 'SIN';
        if (orig && orig['filename']) {
            return orig['filename'];
        }
    }

    const detalleSection = [
        {
            name: 'detalle',
            label: 'Detalle',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Transporte',
            name: 'transporte._id',
            Component: ({...other}) => {
                const {name, control, rules, setValue, ...other1} = other;
                const {
                    field: { onChange, onBlur, value, ref },
                    //fieldState: { invalid, isTouched, isDirty },
                    //formState: { touchedFields, dirtyFields }
                  } = useController({
                    name,
                    control,
                    rules,
                });                
                const onChangeExtra = (ev, el) => {
                    debugger
                    // agrega campos externos
                    const found = options_transports.filter( it => it.value === ev.target.value);
                    if (found && found.length) {
                        setValue('transporte.nombre', found[0].nombre)
                        setValue('transporte.patente', found[0].patente)
                    }
                    onChange(ev, el)
                }
                return <TextInput control={control} name={name} rules={rules} {...other1} onChange={onChangeExtra}/>
            },
            diffFn: (orig) => orig ? "[" + orig['nombre'] + "] " + orig['patente'] : "SIN",
            options: options_transports,
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4, sm:2},
            hidden: tipo === 'WORK-F',
        },
        {
            label: tipo === 'WORK-F' ? 'Recurso' : 'Recursos',
            name: tipo === 'WORK-F' ? 'recurso' : 'recursos',
            options: options_recursos1,
            multiple: tipo === 'WORK-F' ? false : true,
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4, sm:2}
        },
        {
            label: 'Categoria',
            name: 'categoria',
            options: tipo === 'WORK-F' ? options_categoria_of : options_categoria_os,
            rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
            },
            sizes: {xs:4, sm:2}
        },
        {
            label: 'Etapa',
            name: 'etapa',
            sizes: {xs:4, sm:2},
            hidden: tipo === 'WORK-S',
        },
        {
            label: 'Reproceso',
            name: 'reproceso',
            options: options_sino,
            sizes: {xs:4, sm:2},
            hidden: tipo === 'WORK-S',
        },
        {
            label: "Cantidad",
            name: 'cantidad',
            rules: {
                validate: {
                    exists: (v) => v >= 0 || 'Debe ser numero >= 0',
                }
            },
            sizes: {xs:4, sm:2}
        },
        {
            label: "UPS",
            name: 'ups',
            rules: {
                validate: {
                    exists: (v) => v >= 0 || 'Debe ser numero >= 0',
                }
            },
            sizes: {xs:4, sm:2}
        },
        {
            label: "Fecha Entrega",
            name: 'fecha_entrega',
            dateProps: {format:"YYYY-MM-DD"},
            // rules: {
            //     validate: {
            //         exists: (v) => !!v || 'Es obligatorio',
            //     }
            // },
            disabled: disableFechaEntrega,
            hidden: tipo === 'WORK-S',
            sizes: {xs:4, sm:2}
        },
        {
            label: 'Fecha Aprobación',
            name: 'fecha_aprobacion',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: disableFechaAprobacion,
            hidden: tipo === 'WORK-S',
            sizes: {xs:4, sm:2}
        },
    ]
    const descriptionSection = [
        {
            name: 'description',
            label: 'Descripción',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: "Descripción",
            name: 'descripcion',
            sizes: {xs:12}
          },
    ]
    const relacionesSection = [
        {
            name: 'relaciones',
            label: 'Relaciones',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: "Obra",
            name: 'obra',
            Component: ProjectAutocompleteObjectInput,
            diffFn: (orig) => orig ? "[" + orig['carpeta'] + "] " + orig['nombre'] : "SIN",
            sizes: {xs:6, md:3}
        },    
        {
            label: "PV",
            name: 'pv',
            Component: PostSaleAutocompleteObjectInput,
            diffFn: (orig) => orig ? "[" + orig['pv'] + "] " + orig['nombre'] : "SIN",
            sizes: {xs:6, md:3}
        },    
        {
            label: "Planilla",
            name: 'archivo_planilla._id',
            options: opciones_archivo_planilla,
            //disabled: !edit || opciones_archivo_planilla.length === 0,
            diffFn: (orig) => {
                if (!orig) return 'SIN';
                const id = orig['_id'];
                const inx = opciones_archivo_planilla.map(it => it.value).indexOf(id);
                return inx > -1 ? opciones_archivo_planilla[inx].label : "SIN";
            },
            hidden: tipo === 'WORK-S',
            sizes: {xs:6, md:3}
        },
        {
            label: "NP",
            name: 'np',
            Component: ({...other}) => <POrderAutocompleteObjectInput withLink onClick={handleGotoPO} {...other}/>,
            diffFn: (orig) => orig ? "[" + orig['np'] + "] " + " " + orig['status'] : 'SIN',
            sizes: {xs:6, md:3}
        },          
    ]
    const planificacionSection = [
        {
            name: 'planificacion',
            label: 'Planificación',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Plan Fecha Inicio',
            name: 'plan_fecha_inicio',
            dateProps: {format:"YYYY-MM-DD"},
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Plan Fecha Finalizacion',
            name: 'plan_fecha_finalizacion',
            dateProps: {format:"YYYY-MM-DD"},
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Plan duración ',
            name: 'plan_duracion',
            sizes: {xs:4, md:2}
        },
        {
            label: 'Fecha Inicio',
            name: 'fecha_inicio',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },
        {
            label: 'Fecha Finalizacion',
            name: 'fecha_finalizacion',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },
        {
            label: 'Duración ',
            name: 'duracion',
            sizes: {xs:4, md:2}
        },
    ]
    const adminSection = [
        {
            name: 'admin',
            label: 'Admin',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'OF',
            name: 'of',
            sizes: {xs:4, md:2}
        },
        {
            label: 'Status',
            name: 'status',
            options: options_status1,
            sizes: {xs:4, md:2}
        },        
        {
            label: 'Fecha Inicio Colocación',
            name: 'fecha_colocacion_inicio',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },
        {
            label: 'Fecha Fin Colocación',
            name: 'fecha_colocacion_finalizacion',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },
    ]
    let fieldsArr = [...detalleSection, ...descriptionSection, ...relacionesSection, ...planificacionSection]
    if (!hidden && docId !== 'new') fieldsArr = [...fieldsArr, ...adminSection];

    return (
        <Box {...other}>
            <Box sx={{color:'red'}}>
                {error}
            </Box>
            <Form
                loading={loading}
                disabled={!edit}
                fields={fieldsArr}
                defaultValues={doc}
                watchChanges={watchChanges}
                onSubmit={onSubmit}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            //disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={debug}
            />
        </Box>
    );
};

export default WorkOrderEdit;


export const WorkOrderPage = ({...other}) => (
    <PageBase title={"Obra"}>
        <WorkOrderEdit {...other} />
    </PageBase>
)