import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import {Table} from '../../components';
import {getAPI} from '../../utils';
import MeasurementForm from './MeasurementForm';
import moment from 'moment';

export const MeasurementTable = ({obraId, docs=[], onReload=()=>{}, onClick=()=>{}}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [archivo, setArchivo] = useState(false);

    const cols = useMemo(() => [
        {   name: "Archivo", 
            field: v=> <a href='#' onClick={onClick(v)}>{v['archivo']['filename']}</a>,           
            th: { align:'center'},
            td: { align:'center'}, 
        },
        {   name: 'Fecha aprobación', 
            field: v=> v['fecha_aprobacion'] && moment(v['fecha_aprobacion']).format('YYYY-MM-DD'),    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        {   name: 'Fecha entrega',    
            field: v=> v['fecha_entrega'] && moment(v['fecha_entrega']).format('YYYY-MM-DD'),    
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Actualizado',    
            field: v=> v['updated_at'] && moment(v['updated_at']).format('YYYY-MM-DD'),    
            th: { align: 'center'},
            td: { align:'center'}, 
        },
    ], [onClick])
    

    return (
        <Box>
            <Table 
                //sx={{width: "800px", p:1, m:1}}
                cols={cols}
                data={docs}
                tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
                headerProps={{sx: {fontWeight:'bold'}}}
                rowProps={{sx: {m:1}}}
            />

            {false && <MeasurementForm obraId={obraId}/>}
        </Box>
    )
}

export default MeasurementTable