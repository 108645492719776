import React, {forwardRef, useState, useEffect, useCallback, useImperativeHandle} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
// import { useSearchParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import {red} from "@mui/material/colors";
import {PageBase, PageTitle, DataGrid, SearchBox, ExportButton} from '../../../components'; 
import {getAPI, deleteAPI, useTabs, checkUserRoles, debounce} from '../../../utils' ;
import {EXCEL_LIMIT, options_porder_status} from '../../../appConfig';
import ActivityOpenClose  from '../../Activities/ActivityOpenClose';
import moment from 'moment';

const columns = (tipo, onEdit, onDelete, onAddTab) => ([
    {
        field: 'np',
        headerName: 'NP',
        type: 'string',
        renderCell: (params) => {
            return params.row && params.row._id && params.row._id &&  
            <Link href="#" onClick={onEdit(params.row)}>{params.row.np}</Link>
        },
        width: 80,
    },
    {
        field: 'np_provee',
        headerName: 'NP Prove',
        type: 'string',
        width: 80,
    },
    {
        field: 'rec',
        headerName: 'REC',
        type: 'string',
        renderCell: (params) => {
            return params.row && params.row._id && params.row._id &&  
            <Link href="#" onClick={onEdit(params.row)}>{params.row.rec}</Link>
        },
        width: 80,
    },
    {
        field: 'remito',
        headerName: 'REMITO',
        type: 'string',
        width: 140,
    },
    {
        field: 'provee1',
        headerName: 'Proveedor',
        type: 'string',
        renderCell: (params) => {
            const own = params.row && params.row.provee;
            const out = own ?  "[" + own.codigo_proveedor + "] " + own.fullname + "]" || "" : "";
            const link = onAddTab ? (
                <IconButton size='small' onClick={onAddTab('PROVEE')(own ? own : "")}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
            ) : ""
            return out && out.trim() ? <span>{out} {link}</span> : "";          
        },
        width: 280,
    },
    {
        field: 'tipo',
        headerName: 'Tipo',
        type: 'string',
        hide: tipo === 'REC',
        width: 120,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        hide: tipo === 'REC',
        width: 120,
    },
    // {
    //     field: 'obra',
    //     headerName: 'Obra',
    //     type: 'string',    
    //     renderCell: (params) => {
    //         const own = params.row && params.row.obra;
    //         const out = own ?  own.carpeta + " " + own.nombre || "" : "";
    //         const link = onAddTab ? (
    //             <IconButton onClick={onAddTab('OBRA')(own ? own : "")}>
    //                 <ExitIcon fontSize='10'/>
    //             </IconButton>
    //         ) : ""
    //         return out && out.trim() ? <span>{out} {link}</span> : "";          
    //     },
    //     width: 240,
    // },
    {
        field: 'refe',
        headerName: 'Referencias',
        type: 'string',    
        renderCell: (params) => {
            const out = []
            const row = params.row;
            if (row.obra) {
              const tag = <Chip size='small' onClick={onAddTab('OBRA')(row.obra)} label={"OB-" + row.obra.carpeta + " " + row.obra.nombre}/>
              out.push( tag )
            }
            if (row.pv) {
              const tag = <Chip size='small' onClick={onAddTab('PVTA')(row.pv)} label={"PV-" + row.pv.pv + " " + row.pv.nombre}/>
              out.push( tag )
            }
            if (row.of) {
              const tipo = row.of.tipo === 'fabrica' ? "OF-" : "OS-";
              const tag = <Chip size='small' onClick={onAddTab('WORK-F')(row.of)} label={tipo + row.of.of}/>
              out.push( tag )
            }
            // if (row.np) {
            //   const tag = <Chip size='small' onClick={onAddTab('NP')(row.np)} label={"NP-" + row.np.np}/>
            //   out.push( tag )
            // }
            return <Box sx={{overflow: 'hidden'}}>{out}</Box>
              
        },
        width: '240',
    },

    {
        field: 'fecha_entrega',
        headerName: 'Fecha Entrega',
        //type: 'date',
        renderCell: (params) => {
            debugger
            const color = params.row && params.row._id && moment(params.row.fecha_entrega).utc() < moment().utc() ? red[500] : "";
            return params.row && params.row._id  && 
                <Box sx={{color}}>{moment(params.row.fecha_entrega).utc().format("YYYY-MM-DD")}</Box>
        },
        hide: tipo === 'REC',
        width: 120,
    },
    {
        field: 'pedido_orig',
        headerName: 'NP rel',
        type: 'string',
        renderCell: (params) => {
            return params.value && params.value._id && params.value._id &&
                <Link href="#" onClick={onEdit(params.value)}>{params.value.np}</Link>
        },
        width: 120,
    },
    {
        field: 'fecha_recibido',
        headerName: 'Fecha Recibido',
        //type: 'date',
        valueGetter: (params) => {
            return params.value && moment(params.value).utc().format("YYYY-MM-DD")
        },
        hide: tipo === 'NP',
        width: 120,
    },
    {
        field: 'descripcion',
        headerName: 'Descripcion',
        type: 'string',
        hide: tipo === 'REC',
        width: 250,
    },
    {
        field: 'presupuesto',
        headerName: 'Total',
        type: 'number',
        valueGetter: (params) => {
            const mon = params.row.moneda
            return Number(params.value).toFixed(0) + " " + mon
        },
        hide: tipo === 'REC',
        width: 130,
    },
    {
        field: 'detail',
        headerName: 'Lineas',
        type: 'number',
        valueGetter: (params) => {
            return params.value && params.value.length
        },
        width: 80,
    },
    {
        field: "updated_at",
        headerName: 'Actualizado',
        //type: 'date',
        valueGetter: (params) => {
            return params.value && moment(params.value).utc().format("YYYY-MM-DD")
        },
        width: 120
    },
    // {
    //     field: 'actions',
    //     headerName: 'Acciones',
    //     type:'actions',
    //     getActions: (params) => [
    //       <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params.row)} label="Edit" />,
    //     //   <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" showInMenu />,
    //     ]
    // }
])


const IndexPage = forwardRef( ({tipo='NP', addTab}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [filter, setFilter] = useState({status:"-1", obra: "", np: "", provee: "", oper:"-1"});
    const [reload, setReload]   = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'compras-edit'])

    const options_status = [{label: "TODOS", value: "-1"}].concat(options_porder_status);
    const options_trata = [{label: "TODOS", value: "-1"}, {label: "SIN", value: "2"}, {label: 'CON', value: "1"}];

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        getAlert() {
            alert("getAlert from Child");
        }
    }));  
    
    const fn = useCallback( 
        debounce(
            (page, filter, tipo, reload, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                getAPI('/stock/porders/', {...filter, tipo, skip: page*limit, limit})
                .then(data => {
                    setLoading(false);
                    if (!data.list) {
                        debugger 
                        setNum(0)
                        setRows([])
                        return
                    }
                    data.list.forEach( it => {
                        it.id = it._id;
                    });
                    setNum(data.num)
                    setRows(data.list)
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: "error"})
                });
            }, 500),
        []
    )   
    useEffect( () => {
        fn(page, filter, tipo, reload, enqueueSnackbar)
    }, [fn, page, filter, tipo, reload, enqueueSnackbar])

    const handleDelete = (item) => (ev) => {
        debugger
        setLoading(true)
        deleteAPI('/stock/porders/' + item._id)
        .then(data => {
            setLoading(false);
            setReload(true);
            enqueueSnackbar("Se borro el registro correctamente", {variant: "info"})
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }

    const handleFilter = fld => (ev) => {
      setFilter(oo => ({...oo, [fld]: ev.target.value}))
    }    
  
    const handleEdit = (item) => (ev) => {
        debugger
        if (item.np) appendTab('NP')(item)
        if (item.rec) appendTab('REC')(item)
    }

    const handleAddTab = (tipo) => item => ev => {
        if (tipo && item) appendTab(tipo)(item)
    }

    const handleReload = (reload) => {
        debugger
        setReload(reload)
    }

    const configReport = useCallback( async () => {
        const colsNP = [
            {title: 'NP', field: 'np'},
            {title: 'NP Provee', field: 'np_provee'},
            {title: 'Proveedor', field: '_', transform: (v, doc) => {
                    const own = doc.provee;
                    if (own) {
                        return "[" + own.codigo_proveedor + "] " + own.fullname + "]";
                    }
                },
            },
            {title: 'Obra', field: '_', transform: (v, doc) => {
                    const own = doc.obra;
                    if (own) {
                        return "[" + own.carpeta + "] " + own.nombre + "]";
                    }
                },
            },
            {title: 'OF', field: '_', transform: (v, doc) => {
                const own = doc.of;
                if (own) {
                    const tipo = own.tipo === 'fabrica' ? "OF-" : "OS-";
                    return tipo + own.of + " [" + own.status + "]";
                }
            }},
            {title: 'Tipo', field: 'tipo',},
            {title: 'Status', field: 'status',},
            {title: 'Fecha Entrega', field: 'fecha_entrega', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
            {title: 'Fecha Recibido', field: 'fecha_recibido', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
            {title: 'Descripcion', field: 'descripcion'},
            {title: 'Actualizado', field: 'updated_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
        ]
        const colsREC = [
            {title: 'REC', field: 'rec'},
            {title: 'REMITO', field: 'remito'},
            {title: 'NP REL', field: '_', transform: (v, doc) => {
                    const own = doc.pedido_orig;
                    if (own) {
                        return "[" + own.np + "] " + own.status;
                    }
                }
            },
            {title: 'Proveedor', field: '_', transform: (v, doc) => {
                    const own = doc.provee;
                    if (own) {
                        return "[" + own.codigo_proveedor + "] " + own.fullname + "]";
                    }
                },
            },
            {title: 'Obra', field: '_', transform: (v, doc) => {
                    const own = doc.obra;
                    if (own) {
                        return "[" + own.carpeta + "] " + own.nombre + "]";
                    }
                },
            },
            {title: 'Fecha Recibido', field: 'fecha_recibido', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
            {title: 'Actualizado', field: 'updated_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
        ]
        const reportConfig = {
            title: tipo === "NP" ? `Compras` : `Recepciones`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: tipo === 'NP' ? colsNP : colsREC,
            styles: {
                head1:  {backgroundColor:"#dbe0f0", textAlign: 'center'},
                number: {textAlign:'right'},
                row:    {textAlign:"left"}             
            },
        }

        const ret = await getAPI('/stock/porders/', {...filter, tipo, skip: page*limit, limit:EXCEL_LIMIT})

        reportConfig['rows'] = ret['list'];

        return reportConfig;
    }, [filter, tipo, currentUser]);

    const columnVisibilityModel = {
        // Hide columns status and traderName, the other columns will remain visible
        np: tipo === 'NP' ? true : false,
        status: tipo === 'NP' ? true : false,
        tipo: tipo === 'NP' ? true : false,
        descripcion: tipo === 'NP' ? true : false,
        rec: tipo === 'NP' ? false : true,
        remito: tipo === 'NP' ? false : true,
        pedido_orig: tipo === 'NP' ? false : true,
        fecha_entrega: tipo === 'NP' ? true : false
    };

    return (
        <PageBase 
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 
            title={
                <>
                    <PageTitle>{tipo === 'NP' ? "Pedidos" : "Recepciones"} ({num})</PageTitle> 
                    <SearchBox label={tipo==='NP' ? "NP" : "Rec/Rem"} value={filter['np']} onChange={handleFilter('np')}/>
                    <SearchBox label={"Provee"} value={filter['provee']} onChange={handleFilter('provee')}/>
                    <SearchBox label={"Obra"} value={filter['obra']} onChange={handleFilter('obra')}/>
                    {tipo === 'NP' && <SearchBox label={"Status"} select options={options_status} value={filter['status']} onChange={handleFilter('status')}/>}
                    {tipo === 'NP' && <SearchBox sx={{width: 120}} label={'Tratamiento'} select options={options_trata} value={filter['oper']} onChange={handleFilter('oper')}/>}
                </>
            }
            actions={
                <Box sx={{display: 'flex'}}>
                    {esLocalAdmin && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleEdit({np: true, _id: 'new'})}>Nuevo</Button>}
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                    <ActivityOpenClose app='compras' withLabel/>
                </Box>
            }      
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns(tipo, handleEdit, handleDelete, handleAddTab)} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}

                columnVisibilityModel={columnVisibilityModel}
              
            />    

        </PageBase>
    )
})

export default IndexPage;