import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form } from '../../../components'
import { postAPI, checkUserRoles, activityLog } from '../../../utils';
import { options_porder_tipo, options_porder_status, options_moneda, options_lugarentrega } from '../../../appConfig';
import { ContactAutocompleteObjectInput } from '../../Crm/ContactAutocomplete';
import { ProjectAutocompleteObjectInput } from '../../Project/ProjectAutocomplete';
import { PostSaleAutocompleteObjectInput } from '../../PostVenta/PostSaleAutocomplete'
import { WorkOrderAutocompleteObjectInput } from '../../Fabrica/WorkOrderAutocomplete';
import { POrderAutocompleteObjectInput } from './POrderAutocomplete';
import { OperationAutocompleteObjectInput } from '../../Settings/Operation/OperationAutocomplete';

import moment from 'moment'

const POrderEdit = ({doc, disabled=false, onClose, onCancel, debug=false}) => {
    const { enqueueSnackbar } = useSnackbar();
    const operations = useSelector(state => state.operations || []);
    const currentUser = useSelector(state => state.currentUser);
    const notAdmin = !checkUserRoles(currentUser, ['admin', 'local-admin']);
    const [sinTrata, setSinTrata] = useState(false);
    
    const handleSubmit = (data, diffMgs) => {
        debugger
        let id = doc._id;
        if (doc._id === 'new') {
            id = "";
            delete data._id
        }
        if (data.tratamiento == -1) {
            delete data.tratamiento
        }
        postAPI('/stock/porders/' + id, data)
        .then(({res, doc}) => {
            debugger
            console.log(res)
            enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})

            if (id === "") {
                const msg = `Se creó la orden de compra`
                activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg, currentUser})    
            } else {
                activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, diffMgs, currentUser})    
            }
  
            onClose(doc)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
  
    const fields = [
        {
            label: 'NP',
            name: 'np',
            disabled: notAdmin || disabled,
            sizes: {xs:4},
        },
        {
            label: 'Proveedor',
            name: 'provee',
            Component: ({...other}) => ContactAutocompleteObjectInput({...other, provee:'1', withAdd: !disabled}),
            diffFn: (orig) => {
                if (!orig || Object.keys(orig).length === 0) return 'SIN';
                if (orig && orig['fullname']) {
                    return orig['fullname'];
                }        
            },
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            // disabled: (doc._id !== 'new' || (doc.detail && doc.detail.length === 0) ),
            disabled: (doc._id !== 'new' && doc.detail && doc.detail.length > 0),
            sizes: {xs:4}
        },
        {
            label: 'Fecha Entrega',
            name: 'fecha_entrega',
            type: 'date',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4},
        },
        {
            label: 'Tipo',
            name: 'tipo',
            options: options_porder_tipo,
            disabled: doc.status !== 'PARA PEDIR', 
            sizes: {xs:2},
        },
        {
            label: 'Moneda',
            name: 'moneda',
            options: options_moneda,
            disabled: doc.status !== 'PARA PEDIR', 
            sizes: {xs:2},
        },
        {
            label: 'NP Provee',
            name: 'np_provee',
            sizes: {xs:2},
        },

        {
            label: 'Lugar Entrega',
            name: 'lugar_entrega',
            options: options_lugarentrega,
            sizes: {xs:2},
        },
        {
            label: 'Lugar Entrega Dire',
            name: 'lugar_entrega_dire',
            sizes: {xs:2},
        },
        {
            label: 'Descripción',
            name: 'descripcion',
            // rules: {
            //     validate: {
            //         exists: (v) => !!v || 'Es obligatorio',
            //     }
            // },
            sizes: {xs:6},
        },
        {
            label: "Tratamiento",
            name: 'tratamiento',
            Component: OperationAutocompleteObjectInput,
            hidden: (!!sinTrata),
            disabled: doc.status !== 'PARA PEDIR', 
            sizes: {xs:6}
        },
        {
            label: 'Referencias',
            name: 'divider',
            type: 'divider',
            sizes: {xs: 12}
        },
        {
            label: 'Obra',
            name: 'obra',
            Component: ProjectAutocompleteObjectInput,
            sizes: {xs:4}
        },
        {
            label: 'Postventa',
            name: 'pv',
            Component: PostSaleAutocompleteObjectInput,
            sizes: {xs:4}
        },
        {
            label: 'Orden Fabrica',
            name: 'of',
            Component: WorkOrderAutocompleteObjectInput,
            sizes: {xs:4}
        },
        // {
        //     label: 'Orden Compra',
        //     name: 'np',
        //     Component: POrderAutocompleteObjectInput,
        //     sizes: {xs:4}
        // },

        {
            label: 'Para Admin',
            name: 'divider',
            type: 'divider',
            sizes: {xs: 12}
        },
        {
            label: 'Status',
            name: 'status',
            options: options_porder_status,
            disabled: notAdmin || disabled,
            sizes: {xs:2},
        },
        {
            label: 'Fecha Aprobado',
            name: 'fecha_aprobado',
            type: 'date',
            disabled: notAdmin || disabled,
            sizes: {xs:2},
        },
        {
            label: 'Fecha Pedido',
            name: 'fecha_pedido',
            type: 'date',
            disabled: notAdmin || disabled,
            sizes: {xs:2},
        },
        {
            label: 'Fecha Confirmado',
            name: 'fecha_confirmado',
            type: 'date',
            disabled: notAdmin || disabled,
            sizes: {xs:2},
        },
        {
            label: 'Fecha Recibido',
            name: 'fecha_recibido',
            type: 'date',
            disabled: notAdmin || disabled,
            sizes: {xs:2},
        },
    ]


    const watchChanges = (value, name, type, getValues, setValue) => {
        debugger
        if ( type==='change') {
            if (name === 'tipo') {
              const tipo = getValues('tipo');
              if (tipo !== 'MATERIALES') {
                setValue('tratamiento', '')
                setSinTrata(true)
              } else {
                setSinTrata(false)
              }
            }
        } 
    }
  
    console.log(doc._id === 'new' || doc.detail && doc.detail.length > 0)
    return ( 
        <Box>
            <Form 
                fields={fields}
                defaultValues={doc}
                disabled={disabled}
                onSubmit={handleSubmit}
                watchChanges={watchChanges}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={debug}
            />

            {   doc._id !== 'new' && 
                <Box sx={{float:'right'}}>
                    <Typography sx={{color: 'gray'}} variant='body2'>
                        Actualizado {moment(doc.updatedAt).utc().format("YYYY-MM-DD")} / 
                        Creado {moment(doc.createdAt).utc().format("YYYY-MM-DD")}
                    </Typography>
                </Box>
            }

        </Box>
    )
}
  
export default POrderEdit;