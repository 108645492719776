import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {red, green, blue, yellow} from "@mui/material/colors";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ExitIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';

import {Table} from '../../components';
import {getAPI, postAPI, activityLog, checkUserRoles, useTabs} from '../../utils';

import moment from 'moment';
import _ from 'underscore';

export const WorkOrderScheduleTable = ({resource='', tipo='fabrica', withFab=false, reload:reloadProp}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [docs, setDocs] = useState([]);
    const [tots, setTots] = useState({});
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [num, setNum] = useState(0);

    const currentUser = useSelector(state => state.currentUser);
    const resources = useSelector(state => state.resources);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'fabrica-edit'])
    let resourceObj = resource && resource.length > 0 && resources[tipo].filter(it => it.nombre === resource[0])
    if (resourceObj) {
        resourceObj = resourceObj[0]
    }

    const {appendTab} = useTabs();

    useEffect( () => {
        debugger
        if (reloadProp) {
            setReload(true)
        }
    }, [reloadProp])

    useEffect( () => {
        setReload(false)
        setLoading(true)
        getAPI('/work/workorders/schedule', {tipo, resource, withFab})
        .then(data => {
            debugger
            setLoading(false)
            if (data) {
                const list1 = _.sortBy(data.list, it => { return it.fecha_inicio ? it.plan_fecha_inicio : it.plan_fecha_inicio });

                // builds summary of stats with a week-year key
                const list2 = [];
                const res = {};
                let inx = 0;
                let cur = {last_key:-1, cant:0, ups:0, ofs:[]};
                while (list1[inx]) {
                    const row = list1[inx];
                    const fecha = row.plan_fecha_inicio;
                    if (fecha) {
                        const week = moment(fecha).isoWeek();
                        const year = moment(fecha).year();
                        const key = week+'-'+year;

                        if (cur.last_key === -1) {
                            cur.last_key = key;
                            list2.push({summaryKey: key, week, year});
                        }

                        if (cur.last_key !== key) {
                            res[cur.last_key] = {cant: cur.cant, ups: cur.ups, ofs: cur.ofs};

                            cur.cant = 0;
                            cur.ups = 0;
                            cur.ofs = [];
                            cur.last_key = key;
                            list2.push({summaryKey: key, week, year})
                        } 
                        cur.cant += row.cantidad;
                        cur.ups  += row.ups;
                        cur.ofs.push(row.of);

                        const f1 = row.plan_fecha_finalizacion && moment(row.plan_fecha_finalizacion);
                        const f2 = row.fecha_aprobacion && moment(row.fecha_aprobacion);
                        const f3 = row.fecha_entrega && moment(row.fecha_entrega);
                        const f4 = row.plan_fecha_finalizacion && moment(row.plan_fecha_finalizacion);
                        const diffDias = f1 && f2 && f1.diff(f2, 'days');
                        const colEntrega = f4 > f3 ? 'red' : 'inherit';

                        row['plazo'] = diffDias;
                        row['entrega'] =  row.fecha_entrega && moment(row.fecha_entrega).utc().format('MM-DD');
                        row['colEntrega'] = colEntrega;

                        list2.push(row);
                    }
                    inx += 1;
                }
                if (cur.last_key && cur.cant) {
                    res[cur.last_key] = {cant: cur.cant, ups: cur.ups, ofs: cur.ofs};
                }
                setTots(res);
                setDocs(list2);
                setNum(data.num);
            }
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [resource, tipo, withFab, reload, enqueueSnackbar])

    const handlePrioridad = useCallback( (v) => (ev) => {
        const prioridad = !v.prioridad ? 1 : 0;
        
        postAPI('/work/workorders/' + v['_id'], {prioridad})
        .then(data => {
            const msg = `Se cambió la prioridad de la OF a "${prioridad}"`
            enqueueSnackbar(msg, {variant: "info"})
            activityLog({label: "OF-" + v.of, app:'fabrica', docId: v._id, msg, currentUser})    
        
            const newDocs = docs.map(it => {
                if (it._id === v._id) {
                    it.prioridad = prioridad;
                }
                return it;
            })
            setDocs(newDocs);    
            
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [docs, currentUser, enqueueSnackbar])

    const handleSeguimiento = useCallback( (v, fld) => (ev) => {
        debugger
        if (!esLocalAdmin) {return }
        
        const seguimiento = v['seguimiento'] || {};
        seguimiento[fld] = !seguimiento[fld];
        postAPI('/work/workorders/' + v['_id'], {seguimiento})
        .then(data => {
            const msg = `Se cambió el seguimiento de la OF ${fld} a ${seguimiento[fld] ? '1' : '0'}` 
            enqueueSnackbar(msg, {variant: "info"})
            activityLog({label: "OF-" + v.of, app:'fabrica', docId: v._id, msg, currentUser})    
        
            const newDocs = docs.map(it => {
                if (it._id === v._id) {
                    it['seguimiento'] = seguimiento;
                }
                return it;
            })
            setDocs(newDocs);    
            
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [docs, currentUser, enqueueSnackbar])

    const handleLink= tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab( tipo )(item)
    }

    const segFn = useCallback(fld => v => {
        const valid = v['seguimiento'] && !!v['seguimiento'][fld];
        // return valid ? <StarIcon sx={{color:blue[300]}} fontSize='small'/> : <StarBorderIcon  fontSize='small'/>
        return ( 
            <span style={{verticalAlign:'center', width:'100%', display: 'block', cursor: 'pointer'}} onClick={handleSeguimiento(v,fld)}>
                {valid && <StarIcon sx={{color:blue[300], fontSize: 13}}/> } &nbsp;
            </span>
        )
    }, [handleSeguimiento])

    const colsDef = useMemo(() => [
        {
            name: 'RECS',
            field: v=>v.recursos.join(', '),
            th: { sx: {width: '30px'}},
            hide: tipo === 'fabrica'
        },
        {
            name: 'REC',
            field: v=>v.recurso,
            th: { sx: {width: '30px'}},
            hide: tipo === 'servicio'
        },
        {
            name: 'Plazo',
            field: v=>v.plazo,
            th: { sx: {width: '30px'}},
            hide: tipo === 'servicio'
        },
        {
            name: 'Entrega',
            field: v=><span style={{color: v.colEntrega}}>{v.entrega}</span>,
            th: { sx: {width: '30px'}},
            hide: tipo === 'servicio'
        },
        {   
            name: 'Inicio',    
            field: v=> {
                // if (v['fecha_inicio']) {
                //     return moment(v['fecha_inicio']).utc().format('MM-DD')
                // }
                if (v['plan_fecha_inicio']) {
                    return moment(v['plan_fecha_inicio']).utc().format('MM-DD')
                }
            },
            th: { sx: {width: '50px'}},
            td: { align:'center'}, 
        },
        {   
            name: 'Fin',    
            field: v=> {
                let fecha, valor; 
                // if (v['fecha_finalizacion']) {
                //     valor = moment(v['fecha_finalizacion']);
                //     fecha = moment(v['fecha_finalizacion']).utc().format('MM-DD');
                // }
                if (v['plan_fecha_finalizacion']) {
                    valor = moment(v['plan_fecha_finalizacion']);
                    fecha = moment(v['plan_fecha_finalizacion']).utc().format('MM-DD');
                }
                const entrega = v['np'] && moment(v['np']['fecha_recibido'] || v['np']['fecha_entrega']);
                return fecha && <Box sx={{color: valor < entrega && red[500]}}>{fecha}</Box>
            },
            th: {sx: {width: '50px'}},
            td: { align: 'left'}, 
        },
        {   
            name: 'Proyecto',    
            field: v=> {
                const obra = v['obra'];
                const link = (
                    <IconButton size='small' onClick={handleLink('OBRA')(obra)}>
                        <ExitIcon fontSize='10'/>
                    </IconButton>
                )
                if (obra) return (
                    <span>OB {obra['carpeta']} ({obra['nombre']}) {obra['ownerId']&& obra['ownerId']['username']} {link}</span>
                )        
            },
            th: {sx: {flex: 1}},
            td: { align: 'left'}, 
        },
        {
            name: "OF REL",
            field: v => {
                const link = (it) => (
                    <IconButton size='small' onClick1={handleLink('WORK-F')(it)}>
                        <ExitIcon fontSize='10'/>
                    </IconButton>
                )
                const ofs = (v['ofs_rel'] || []).map(it => (
                    <span>
                        <a href="#" onClick={handleLink('WORK-F')(it)}>{it.of + " (" + it.status.slice(0,3) + ")"}</a>
                        &nbsp;
                    </span>
                ));
                if (ofs) return (
                    <span>{ofs}</span>
                )
            },
            hide: tipo === 'fabrica'
        },
        {   
            name: tipo === 'fabrica' ? "OF" : "OS",            
            field: v=> {
                const of = v['of'];
                const link = (
                    <IconButton size='small' onClick={handleLink(tipo === 'fabrica'? 'WORK-F' : 'WORK-S')(v)}>
                        <ExitIcon fontSize='10'/>
                    </IconButton>
                )
                if (of) return (
                    <span>{of} ({v['status']}) {link}</span>
                )        
            },
            th: { sx: {flex: 0.55}},
            td: { align: 'left'}, 
        },
        {   
            name: 'Descripción', 
            field: v=> v['descripcion'],    
            th: {sx: {flex: 0.5}},
            td: { align: 'left'}, 
        }, 
        {   
            name: '',    
            field: v => {
                return (
                    <span style={{cursor: 'pointer'}} onClick={handlePrioridad(v)}>
                        { v.prioridad === 1 ? <StarIcon color='red' fontSize='small'/> : <RadioButtonUncheckedIcon fontSize='small'/>}
                    </span>
                )
            },       
            th: { sx: {width: '20px'}},
            td: { align:'center'}, 
        },
        {   
            name: 'Cant',    
            field: v => v['cantidad'],       
            th: { sx: {width: '30px'}},
            td: { align:'center'}, 
        },
        {   
            name: 'UPS',    
            field: v => v['ups'],       
            th: { sx: {width: '30px'}},
            td: { align:'center'}, 
        },
        {   
            name: 'REQ',    
            field: v => {
                if (!v['workStat']) return "";
                let color = "";
                if (v['workStat']['res'] === 0 && v['workStat']['rec'] === v['workStat']['req']) { color = green[500] }
                if (v['workStat']['res'] >= 0 && v['workStat']['rec'] < v['workStat']['req']) { color = yellow[500] }

                return <Box sx={{backgroundColor:color}}>{v['workStat']['res']} / {v['workStat']['rec']} / {v['workStat']['req']}</Box>
                
            },       
            th: { sx: {width: '90px'}},
            td: { align:'center'}, 
        },
        {   
            name: 'NP',    
            field: v => {
                const rel = v['np'] && v['np']['np_provee'];
                const color = v['np'] && v['np']['status'] === 'PARA PEDIR' ? red[500] : "";
                return v['np'] && 
                    <Box sx={{color}}>
                        <Tooltip title={"NP Provee #" + rel}>
                            <a style={{color}} href='#' onClick={handleLink('NP')(v['np'])}>{v['np']['np']}</a> &nbsp;
                            {moment(v['np']['fecha_entrega']).utc().format('MM-DD')} &nbsp;
                            ({v['np']['status']})
                        </Tooltip>
                    </Box>
            },
            th: { sx: {width: '70px'}},
            td: { align:'left'}, 
        },
        {   
            name: 'OS',    
            field: v => {
                const of = v['of_rel'];
                const inicio = of && moment(of['fecha_plan_inicio']).utc();
                const color = of && inicio && v['fecha_plan_finalizacion'] && inicio < moment(v['fecha_plan_finalizacion']).utc() ? red[500] : ""
                return <Box sx={{color}}>
                            <a style={{color}} href='#' onClick={handleLink('WORK-S')(of)}>{of && of['of']}</a> &nbsp;
                            {of && inicio.format('MM-DD')} &nbsp;
                            {of && ( of['status'])}
                        </Box>
            },
            th: { sx: {width: '70px'}},
            td: { align:'left'}, 
            hide: tipo === 'servicio'
        },
        {   
            name: 'C',    
            field: segFn('C'),
            th: { sx: {width: '10px'}},
            td: { sx: {textAlign:'center', borderLeft: '1px solid #AAA'} }, 
            hide: tipo === 'servicio'
        },
        {   name: 'R',    
            field: segFn('R'),       
            th: { sx: {width: '10px'}},
            td: { sx: {textAlign:'center', borderLeft: '1px solid #AAA'}}, 
            hide: tipo === 'servicio'
        },
        {   name: 'M',    
            field: segFn('M'),       
            th: { sx: {width: '10px'}},
            td: { sx: {textAlign:'center', borderLeft: '1px solid #AAA'}}, 
            hide: tipo === 'servicio'
        },
        {   name: 'S',    
            field: segFn('S'),       
            th: { sx: {width: '10px'}},
            td: { sx: {textAlign:'center', borderLeft: '1px solid #AAA'}}, 
            hide: tipo === 'servicio'
        },
        {   name: 'A',    
            field: segFn('A'),       
            th: { sx: {width: '10px'}},
            td: { sx: {textAlign:'center', borderLeft: '1px solid #AAA'}}, 
            hide: tipo === 'servicio'
        },
        {   name: 'T',    
            field: segFn('T'),       
            th: { sx: {width: '10px'}},
            td: { sx: {textAlign:'center', borderLeft: '1px solid #AAA'}}, 
            hide: tipo === 'servicio'
        },
    ], [handlePrioridad, segFn, tipo])
    
    const colsHead = [
        {   
            field: row => {
                const sum = tots[row.summaryKey];
                const cant = sum ? sum['cant'] : 0;
                const ups = sum ? sum['ups'] : 0;
                const ofs = sum ? sum['ofs'] : "";
                const totalCarga = Number(resourceObj['upsSemana'])
                const porcentajeCarga = resourceObj && resourceObj['upsSemana'] ? Number(100*ups/totalCarga).toFixed(0) : 'SIN';

                return (
                    <Box sx={{p:0.5, fontSize: '13px', fontWeight:600, color: '#000'}}>
                        <Box sx={{float: 'left', fontSize: '16px'}}>Semana {row.week}/{row.year}</Box>
                        Cantidad={cant} UPS={ups} / {totalCarga} (<span style={{color: (porcentajeCarga > 100 ? 'red' : "")}}>{porcentajeCarga}%</span>) - 
                        Ver Mat. Pendiente {ofs.length}
                        <IconButton size='small' onClick={handleLink('MAT')({id: row.summaryKey, ofs, pendiente:true})}>
                            <ExitIcon fontSize='10'/>
                        </IconButton>
                    </Box>
                )
            }, 
            th: { sx: {width: '10px'}},
            td: { colSpan: colsDef.length, sx: {fontSize: '14px', textAlign:'center', backgroundColor: "#88ab8bcf"}}, 
        },
    ]

    const colsSelect = (row) => {
        return row && row['summaryKey'] ? colsHead : colsDef;
    }

    return (
        <Table 
            //sx={{width: "800px", p:1, m:1}}
            loading={loading}
            cols={colsDef}
            colsSelect={colsSelect}
            data={docs}
            tableProps={{size:'small', padding: 'none', stickyHeader: true}}
            headerProps={{
                sx: { 
                    th: {
                        fontSize: 13, 
                        fontWeight:'bold', 
                        color:'white', 
                        backgroundColor: "#549857", //green[500], 
                        textAlign: 'center', 
                        borderRight: '1px solid #fff',
                        p: '2px 5px'
                    }
                }
            }}
            rowProps={{sx: {m:1, 'td': {fontSize: '12px', p: '0px 5px'}}}}
        />
    )
}

